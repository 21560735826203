var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"row justify-content-end"},[_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.$router.push({
        name:'stocks-designations-create'
      })}}},[_c('i',{staticClass:"bi bi-plus fs-5"}),_c('span',[_vm._v(" Créer une désignation de stock ")])])]),_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-secondary",on:{"click":function($event){return _vm.refresh()}}},[_c('i',{staticClass:"bi bi-arrow-clockwise fs-5"}),_c('span',[_vm._v(" Actualiser ")])])]),_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-secondary",on:{"click":function($event){return _vm.print()}}},[_c('i',{staticClass:"bi bi-printer fs-5"}),_c('span',[_vm._v(" Imprimer ")])])]),_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-secondary",on:{"click":function($event){return _vm.sendByEmail()}}},[_c('i',{staticClass:"bi bi-envelope fs-5"}),_c('span',[_vm._v(" Envoyer ")])])])]),_c('br'),_c('table',{staticClass:"table table-striped table-hover"},[_vm._m(0),_c('tbody',_vm._l((_vm.stocks),function(stock,index){return _c('tr',{key:index++},[_c('td',[_vm._v(" "+_vm._s(index)+" ")]),_c('td',[(stock)?_c('span',[_vm._v(" "+_vm._s(stock.item_reference)+" ")]):_vm._e()]),_c('td',[(stock)?_c('span',[_vm._v(" "+_vm._s(stock.itemFullName)+" ")]):_vm._e()]),_c('td',[(stock)?_c('div',[_vm._v(" "+_vm._s(stock.sectionFullName)+" ")]):_vm._e()]),_c('td',[(stock)?_c('div',[_vm._v(" "+_vm._s(stock.fullName)+" ")]):_vm._e()]),_c('td',{staticClass:"col"},[_c('span',{staticClass:"p-2 rounded-2",class:stock.quantity == 0
                ? 'bg-danger text-white'
                : stock.quantity <= 5
                ? 'bg-warning'
                : 'text-success'},[(stock.measuring == 'KG')?_c('strong',{style:({ fontWeight: 'bolder !important' })},[_vm._v(" "+_vm._s(parseFloat(stock.quantity).toFixed(3))+" ")]):_c('strong',{style:({ fontWeight: 'bolder !important' })},[_vm._v(" "+_vm._s(stock.quantity)+" ")]),_vm._v(" "+_vm._s(stock.measuring)+" ")])]),_c('td',[(stock & _vm.$userRole == 'Admin')?_c('div',[_vm._v(" "+_vm._s(_vm._f("$toFixedWithSpace")(stock.purchasePriceTTC))+" ")]):_vm._e()]),_c('td',[(stock.cold_room)?_c('span',[_vm._v(" "+_vm._s(stock.cold_room.number)+" ")]):_vm._e()]),(_vm.$userRole == 'Admin')?_c('td',{staticClass:"p-0 m-0"},[_c('div',{staticClass:"d-flex"},[_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.$router.push({
                  name: 'stocks-edit',
                  params: { reference: stock.reference },
                })}}},[_c('i',{staticClass:"bi bi-pencil-square fs-5"})]),_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.$router.push({
                  name: 'stocks-delete',
                  params: { reference: stock.reference },
                })}}},[_c('i',{staticClass:"bi bi-file-x-fill fs-5 text-danger"})])])]):_vm._e()])}),0),_c('tfoot',[_c('tr',[_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td',[_c('span',{staticClass:"bg-secondary py-1 px-2 rounded text-white"},[_vm._v(" "+_vm._s(parseFloat(_vm.totalQuantity).toFixed(3))+" ")])]),_c('td',[(_vm.$userRole == 'Admin')?_c('span',{staticClass:"bg-primary py-1 px-2 rounded text-white"},[_vm._v(" "+_vm._s(parseFloat(_vm.totalPurchasePriceTTC).toFixed(2))+" ")]):_vm._e()]),_c('td'),_c('td')])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}}),_c('th',{attrs:{"scope":"col"}},[_vm._v("Référence")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Désignation")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Catégorie")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Stock / DPT")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Quantité")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Prix d'achat")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Nº de Chambre")]),_c('th',{attrs:{"scope":"col"}})])])
}]

export { render, staticRenderFns }